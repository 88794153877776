<template>
  <div class="accountList">
    <cell-group title="切换账号">
      <cell v-for="(item,index) in AccountList" :key="index" @click="changeAccount(item)">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <img class="headImg" v-if="item.headimgurl" :src="item.headimgurl" alt />
          <icon class="headImg" name="user-o" v-else/>
          <span class="custom-title">{{item.mobile}}</span>
        </template>

        <template #right-icon>
          <span class="online"  v-if="item.selected == 1">在线</span>
          <icon v-else name="cross" size="20" color="#ff0000" @click.stop="remove_user(item, 1)"/>
        </template>
      </cell>
    </cell-group>
    <cell-group title="微信账号">
      <cell v-for="(item,index) in H5AccountList" :key="index" @click="changeAccount(item)">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <img class="headImg" v-if="item.headimgurl" :src="item.headimgurl" alt />
          <icon class="headImg" name="user-o" v-else/>
          <span class="custom-title">{{item.mobile}}</span>
        </template>

        <template #right-icon>
          <span class="online"  v-if="item.selected == 1">在线</span>
          <icon v-else name="cross" size="20" color="#ff0000" @click.stop="remove_user(item, 2)"/>
        </template>
      </cell>
    </cell-group>
    <van-dialog v-model="show" title="验证身份" show-cancel-button :before-close="make_remove">
      <field v-model="phone" label="手机号" disabled />
      <field v-model="code" center clearable label="短信验证码" placeholder="请输入短信验证码">
        <template #button>
          <Button size="small" :disabled="btn_status" type="primary" @click="send_code">{{code_text}}</Button>
        </template>
      </field>
    </van-dialog>
    <div class="changeNewAccount">
      <Button type="primary" block @click="changeNewAccount">切换新账号</Button>
    </div>
  </div>
</template>

<script>
import Creditshop from "@api/creditshop";
import UserInfo from "@api/user";

import { Cell, CellGroup, Icon, Button, Dialog, Field } from "vant";
export default {
  components: {
    Cell,
    CellGroup,
    Icon,
    Button,
    Field,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      show: false,
      phone: "",
      code: "",
      type: "",
			signInStatus: false,
			code_text: "获取验证码",
      timer: null,
      now_item: {},
      btn_status: false,
      AccountList: [],
      H5AccountList: []
    };
  },
  created() {
    this.phone = localStorage.getItem('info_mobile');
  },
  mounted() {
    this.getAccountList();
    this.h5list();
  },
  methods: {
		send_code() {
			const TIME_COUNT = 60;
			if (!this.btn_status) {
				if (!this.phone) {
					this.$toast("手机号码不能为空");
				} else {
					if (!this.timer) {
            this.count = TIME_COUNT;
            // 发送验证码
            this.getPhone();
						this.timer = setInterval(() => {
							if (this.count > 0 && this.count <= TIME_COUNT) {
								this.count--;
								this.code_text = this.count + "s";
								this.btn_status = true;
							} else {
								this.btn_status = false;
								this.timer = null;
								this.code_text = "获取验证码";
							}
						}, 1000);
					}
				}
			}
    },
    async getPhone() {
      try {
        const res = await Creditshop.send_sms();
        this.$toast(res.msg);
      } catch (error) {
        console.log(error);
      }
    },
    // 移除账号
    async remove_user(item, type) {
      this.now_item = item;
      this.type = type;
      this.show = true;
    },
    // H5账号列表
    async h5list() {
      try {
        const res = await UserInfo.wxAccountList();
        if (res.code == 200) {
          this.H5AccountList = res.data;
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    // 确认删除
    make_remove(action, done) {
      if (action === 'confirm') {
        switch (this.type) {
          case 1:
            this.del_user1();
            done();
            break;
          case 2:
            this.del_user2();
            done();
            break; 
          default:
            break;
        }
      } else {
        done();
      }
    },
    // 删除 1
    async del_user1() {
      try {
        const res = await UserInfo.removeAccount(this.code, this.now_item.mobile);
        this.$toast(res.msg);
        this.h5list();
      } catch (error) {
        console.log(error);
      }
    },
    // 删除 2
    async del_user2() {
      try {
        const res = await UserInfo.removeWxAccount(this.code, this.now_item.wx_id);
        this.$toast(res.msg);
        this.getAccountList();
      } catch (error) {
        console.log(error);
      }
    },
    // 账号列表
    async changeAccount(item) {
      try {
        if (item.selected == 0) {
          const res = await Creditshop.changeAccount(item.mobile);
          if (res.code == 200) {
            this.$toast(res.msg);
            localStorage.setItem("access_token", res.data.app_token);
            sessionStorage.setItem("access_token", res.data.app_token)
            this.AccountList = [];
            this.getAccountList();
          }
          console.log(res);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAccountList() { // 账号列表
     const res = await Creditshop.getAccountList();
     if (res.code == 200) {
       this.AccountList = res.data;
     }
     console.log(res);
    },
    async changeNewAccount() {
      try {
        const res = await Creditshop.changeAddAccount();
        console.log(res);
        if (res.code == 200) {
          localStorage.setItem("access_token", res.data.wx_token);
          sessionStorage.setItem("access_token", res.data.wx_token)
          this.$router.push({
            path: "/goVip",
            query: {
              appid: localStorage.getItem("app_id")
            }
          })
        }
      } catch (error) {
        console.log(error);
      }
      
    }
  }
};
</script>

<style lang="scss" scoped>
.accountList {
  width: 100%;
  height: 100%;
  position: relative;

  .hint {
    padding: 10px;
  }
  .online {
    position: relative;
    color: #3ed346;
  }
  .online::before {
    content: "·";
    position: absolute;
    font-size: 50px;
    right: 28px;
  }
  .headImg {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    margin-right: 5px;
    font-size: 16px;
  }

  .changeNewAccount {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>

<style lang="scss">
.accountList {
  .van-cell__title {
    display: flex;
    align-items: center;
  }
  .van-icon {
    line-height: 24px;
  }
}
</style>